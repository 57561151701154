<template>
  <v-card>
    <v-tabs v-model="model" background-color="primary">
      <v-tabs-slider color="rgb(0, 0, 0)"></v-tabs-slider>
      <v-tab :key="1" :href="`#tab-1`"> Complaints </v-tab>
      <v-tab :key="2" :href="`#tab-2`"> Notes </v-tab>
      <v-tab :key="3" :href="`#tab-3`"> Feedback </v-tab>
      <v-tab :key="4" :href="`#tab-4`"> Risks </v-tab>
      <v-tab :key="5" :href="`#tab-5`"> Incidents </v-tab>
      <v-tab :key="6" :href="`#tab-6`"> Alerts </v-tab>
    </v-tabs>

    <v-tabs-items v-model="model">
      <v-tab-item :key="1" :value="`tab-1`">
        <v-col class="mt-6 pb-0" cols="12"></v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Email Address"
              v-model="newComplaintsEmail"
              placeholder="Email Address"
              class="purple-input"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="addComplaintsEmails">Add</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="deleteComplaintsEmails">Delete</v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-list density="compact">
              <v-list-subheader class="listsubheader">Emails:</v-list-subheader>
              <v-list-item
                v-for="(item, i) in complaints"
                :key="i"
                :value="item"
                color="primary"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-tab-item>
      <v-tab-item :key="2" :value="`tab-2`">
        <v-col class="mt-6 pb-0" cols="12"></v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Email Address"
              v-model="newNotesEmail"
              placeholder="Email Address"
              class="purple-input"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="addNotesEmails">Add</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="deleteNotesEmails">Delete</v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-list>
              <v-list-subheader class="listsubheader">Emails:</v-list-subheader>
              <v-list-item
                v-for="(item, i) in notes"
                :key="i"
                :value="item"
                color="primary"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-tab-item>
      <v-tab-item :key="3" :value="`tab-3`">
        <v-col class="mt-6 pb-0" cols="12"></v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Email Address"
              v-model="newFeedbackEmail"
              placeholder="Email Address"
              class="purple-input"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="addFeedbackEmails">Add</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="deleteFeedbackEmails">Delete</v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-list>
              <v-list-subheader class="listsubheader">Emails:</v-list-subheader>
              <v-list-item
                v-for="(item, i) in feedback"
                :key="i"
                :value="item"
                color="primary"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-tab-item>
      <v-tab-item :key="4" :value="`tab-4`">
        <v-col class="mt-6 pb-0" cols="12"></v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Email Address"
              v-model="newRiskEmail"
              placeholder="Email Address"
              class="purple-input"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="addRiskEmails">Add</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="deleteRiskEmails">Delete</v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-list>
              <v-list-subheader class="listsubheader">Emails:</v-list-subheader>
              <v-list-item
                v-for="(item, i) in risks"
                :key="i"
                :value="item"
                color="primary"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-tab-item>
      <v-tab-item :key="5" :value="`tab-5`">
        <v-col class="mt-6 pb-0" cols="12"></v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Email Address"
              v-model="newIncidentEmail"
              placeholder="Email Address"
              class="purple-input"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="addIncidentEmails">Add</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="deleteIncidentEmails">Delete</v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-list>
              <v-list-subheader class="listsubheader">Emails:</v-list-subheader>
              <v-list-item
                v-for="(item, i) in incidents"
                :key="i"
                :value="item"
                color="primary"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-tab-item>
      <v-tab-item :key="6" :value="`tab-6`">
        <v-col class="mt-6 pb-0" cols="12"></v-col>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Email Address"
              v-model="newAlertEmail"
              placeholder="Email Address"
              class="purple-input"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="addAlertEmails">Add</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn v-on:click="deleteAlertEmails">Delete</v-btn>
          </v-col>
        </v-row>
        <v-col cols="12" class="pt-0">
          <v-card>
            <v-list>
              <v-list-subheader class="listsubheader">Emails:</v-list-subheader>
              <v-list-item
                v-for="(item, i) in alerts"
                :key="i"
                :value="item"
                color="primary"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import * as notificationService from "../../services/notificationService";
export default {
  data: () => ({
    model: "tab-1",
    complaints: [],
    notes: [],
    risks: [],
    incidents: [],
    items: [],
    feedback: [],
    alerts: [],
    newComplaintsEmail: "",
    newNotesEmail: "",
    newFeedbackEmail: "",
    newRiskEmail: "",
    newIncidentEmail: "",
    newAlertEmail: "",
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
  },
  methods: {
    async init() {
      await notificationService
        .getCompalintById(this.currentOrganisation.id)
        .then((doc) => {
          let temp = [];
          temp = doc.data();
          this.complaints = temp.Emails;
          console.log(this.complaints);
        });

      await notificationService.getNoteById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.notes = temp.Emails;
        console.log(this.notes);
      });
     await notificationService.getFeedbackById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.feedback = temp.Emails;
        console.log(this.feedback);
      });
    
    await notificationService.getRiskById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.risks = temp.Emails;
        console.log(this.risks);
      });
      await notificationService.getIncidentById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.incidents = temp.Emails;
        console.log(this.incidents);
      });
      await notificationService.getAlertById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.alerts = temp.Emails;
        console.log(this.alerts);
      });
    },
    addComplaintsEmails() {
      notificationService
        .addCompEmail(this.currentOrganisation.id, this.newComplaintsEmail)
        .then((results) => {
          console.log(results);
        });
      this.complaints.push(this.newComplaintsEmail);
    },
    deleteComplaintsEmails() {
      const index = this.complaints.indexOf(this.newComplaintsEmail);
      if (index > -1) {
        this.complaints.splice(index, 1);
      }
      notificationService
        .removeCompEmail(this.currentOrganisation.id, this.newComplaintsEmail)
        .then((results) => {
          console.log(results);
        });
    },
    addNotesEmails() {
      notificationService
        .addNoteEmail(this.currentOrganisation.id, this.newNotesEmail)
        .then((results) => {
          console.log(results);
        });
      this.notes.push(this.newNotesEmail);
    },
    deleteNotesEmails() {
      const index = this.notes.indexOf(this.newNotesEmail);
      if (index > -1) {
        this.notes.splice(index, 1);
      }
      notificationService
        .removeNoteEmail(this.currentOrganisation.id, this.newNotesEmail)
        .then((results) => {
          console.log(results);
        });
    },

    addFeedbackEmails() {
      notificationService
        .addFeedbackEmail(this.currentOrganisation.id, this.newFeedbackEmail)
        .then((results) => {
          console.log(results);
        });
      this.feedback.push(this.newFeedbackEmail);
    },
    deleteFeedbackEmails() {
      const index = this.feedback.indexOf(this.newFeedbackEmail);
      if (index > -1) {
        this.feedback.splice(index, 1);
      }
      notificationService
        .removeFeedbackEmail(this.currentOrganisation.id, this.newFeedbackEmail)
        .then((results) => {
          console.log(results);
        });
    },

     addRiskEmails() {
      notificationService
        .addRiskEmail(this.currentOrganisation.id, this.newRiskEmail)
        .then((results) => {
          console.log(results);
        });
      this.risks.push(this.newRiskEmail);
    },
    deleteRiskEmails() {
      const index = this.risks.indexOf(this.newRiskEmail);
      if (index > -1) {
        this.risks.splice(index, 1);
      }
      notificationService
        .removeRiskEmail(this.currentOrganisation.id, this.newRiskEmail)
        .then((results) => {
          console.log(results);
        });
    },

    addIncidentEmails() {
      notificationService
        .addIncidentEmail(this.currentOrganisation.id, this.newIncidentEmail)
        .then((results) => {
          console.log(results);
        });
      this.incidents.push(this.newIncidentEmail);
    },
    deleteIncidentEmails() {
      const index = this.incidents.indexOf(this.newIncidentEmail);
      if (index > -1) {
        this.incidents.splice(index, 1);
      }
      notificationService
        .removeIncidentEmail(this.currentOrganisation.id, this.newIncidentEmail)
        .then((results) => {
          console.log(results);
        });
    },

     addAlertEmails() {
      notificationService
        .addAlertEmail(this.currentOrganisation.id, this.newAlertEmail)
        .then((results) => {
          console.log(results);
        });
      this.alerts.push(this.newAlertEmail);
    },
    deleteAlertEmails() {
      const index = this.alerts.indexOf(this.newAlertEmail);
      if (index > -1) {
        this.alerts.splice(index, 1);
      }
      notificationService
        .removeAlertEmail(this.currentOrganisation.id, this.newAlertEmail)
        .then((results) => {
          console.log(results);
        });
    },

  },
};
</script>
<style>
.listsubheader {
  color: #156a59;
}
</style>

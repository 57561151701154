import Vue from "vue";
import firebase from "firebase";"@firebase/firestore"

function getNotificationsRef(orgId) {
    return Vue.prototype.$db
      .collection("organisations")
      .doc(orgId)
      .collection("notifications")
     
  }
  export async function getCompalintById(orgId) {
    return getNotificationsRef(orgId)
      .doc("Complaints")
      .get();
  }
  export async function getNoteById(orgId) {
    return getNotificationsRef(orgId)
      .doc("Notes")
      .get();
  }  
  export async function getFeedbackById(orgId) {
    return getNotificationsRef(orgId)
      .doc("Feedback")
      .get();
  }  
  export async function getRiskById(orgId) {
    return getNotificationsRef(orgId)
      .doc("Risks")
      .get();
  }  

  export async function getIncidentById(orgId) {
    return getNotificationsRef(orgId)
      .doc("Incidents")
      .get();
  }  

  export async function getAlertById(orgId) {
    return getNotificationsRef(orgId)
      .doc("Alerts")
      .get();
  }  

  export async function addCompEmail(orgId, compEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Complaints")
      .update({ Emails: firebase.firestore.FieldValue.arrayUnion(compEmail)});
  }
  

  export async function removeCompEmail(orgId, compEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Complaints")
      .update({ Emails: firebase.firestore.FieldValue.arrayRemove(compEmail)});
  }

  export async function addNoteEmail(orgId, noteEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Notes")
      .update({ Emails: firebase.firestore.FieldValue.arrayUnion(noteEmail)});
  }
  

  export async function removeNoteEmail(orgId, noteEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Notes")
      .update({ Emails: firebase.firestore.FieldValue.arrayRemove(noteEmail)});
  }

  export async function addFeedbackEmail(orgId, feedEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Feedback")
      .update({ Emails: firebase.firestore.FieldValue.arrayUnion(feedEmail)});
  }
  

  export async function removeFeedbackEmail(orgId, feedEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Feedback")
      .update({ Emails: firebase.firestore.FieldValue.arrayRemove(feedEmail)});
  }

  export async function addRiskEmail(orgId, riskEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Risks")
      .update({ Emails: firebase.firestore.FieldValue.arrayUnion(riskEmail)});
  }
  

  export async function removeRiskEmail(orgId, riskEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Risks")
      .update({ Emails: firebase.firestore.FieldValue.arrayRemove(riskEmail)});
  }

  export async function addIncidentEmail(orgId, newIncidentEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Incidents")
      .update({ Emails: firebase.firestore.FieldValue.arrayUnion(newIncidentEmail)});
  }
  

  export async function removeIncidentEmail(orgId, newIncidentEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Incidents")
      .update({ Emails: firebase.firestore.FieldValue.arrayRemove(newIncidentEmail)});
  }
  export async function addAlertEmail(orgId, newAlertEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Alerts")
      .update({ Emails: firebase.firestore.FieldValue.arrayUnion(newAlertEmail)});
  }
  

  export async function removeAlertEmail(orgId, newAlertEmail) {
   
    return getNotificationsRef(orgId)
      .doc("Alerts")
      .update({ Emails: firebase.firestore.FieldValue.arrayRemove(newAlertEmail)});
  }